/* .cs_slide {
  width: 95% !important;
} */

.cs_departments.cs_style_1 .cs_department:hover {
  -webkit-transform: translateY(-20px) !important;
  transform: translateY(-20px) !important;
  /* background-color: #307bc4 !important; */
}
.cs_departments.cs_style_1 .cs_department:hover img {
  -webkit-filter: brightness(0) invert(1) !important;
  filter: brightness(0) invert(1) !important;
}
.cs_departments.cs_style_1 .cs_department:hover .cs_heading_color {
  color: #fff !important;
}

.department-carousal .slick-prev,
.department-carousal .slick-next {
  z-index: 2;
}
/* .department-carousal:hover .slick-prev,
.department-carousal:hover .slick-next {
  display: block !important;
} */

/* .department-carousal .slick-prev:before {
  content: url(../../../public/assets/img/icons/left_arrow_blue.svg) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.department-carousal .slick-next:before {
  content: url(../../../public/assets/img/icons/right_arrow_blue.svg) !important;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.cs_department_title {
  font-size: 17px !important;
}
