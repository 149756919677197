/* body {
  margin: 0;
  background-color: #000;
  color: #eee;
  font-family: Poppins;
  font-size: 12px;
} */

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

a {
  text-decoration: none;
}

/* slider section  */

.slider {
  height: calc(100vh - 100px) !important;
  /* margin-top: -50px; */
  width: 100vw;
  overflow: hidden;
  position: relative;
}
.slider .list .item {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0 0 0 0;
}
.slider .list .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider .list .item .content {
  position: absolute;
  top: 20%;
  width: 1140px;
  max-width: 80%;
  left: 50%;
  transform: translateX(-50%);
  padding-right: 30%;
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 5px 10px #0004;
}
.slider .list .item.active .content {
  z-index: 3;
}

.slider .list .item .content .title,
.slider .list .item .content .type {
  font-size: 60px;
  font-weight: bold;
  line-height: 1em;
  margin-bottom: 25px;
}
.slider .list .item .type {
  /* color: #14ff72cb; */
  color: #fff;
}
.slider .list .item .button {
  display: grid;
  grid-template-columns: repeat(2, 130px);
  grid-template-rows: 40px;
  gap: 5px;
  margin-top: 20px;
}
.slider .list .item .button button {
  border: none;
  background-color: #eee;
  font-family: Poppins;
  font-weight: 500;
  cursor: pointer;
  transition: 0.4s;
  letter-spacing: 2px;
}

.slider .list .item .button button:hover {
  letter-spacing: 3px;
}
.slider .list .item .button button:nth-child(2) {
  background-color: transparent;
  border: 1px solid #fff;
  color: #eee;
}

/* Thumbnail Section  */
.thumbnail {
  position: absolute;
  bottom: 60px;
  left: 70%;
  width: max-content;
  z-index: 1;
  display: flex;
  gap: 20px;
}

.thumbnail .item {
  /* width: 150px;
  height: 220px; */
  width: 130px;
  height: 180px;
  flex-shrink: 0;
  position: relative;
}

.thumbnail .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.3);
}

/* nextPrevArrows Section  */
.nextPrevArrows {
  position: absolute;
  top: 80%;
  right: 52%;
  z-index: 100;
  width: 300px;
  max-width: 30%;
  display: flex;
  gap: 10px;
  align-items: center;
}
.nextPrevArrows button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  /* background-color: #14ff72cb; */
  border: none;
  /* color: #fff; */
  color: #274760;
  font-family: monospace;
  font-weight: bold;
  font-size: 20px;
  transition: 0.5s;
  cursor: pointer;
}
.nextPrevArrows button:hover {
  background-color: #fff;
  color: #000;
}

/* Animation Part */
.active {
  z-index: 1;
}

.item.active .shadow {
  background-color: #00000080;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}

/* Animation for text in the active item */
.slider .list .item.active .content .title,
.slider .list .item.active .content .type,
.slider .list .item.active .content .description,
.slider .list .item.active .content .buttons {
  transform: translateY(50px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent 0.5s linear forwards;
}

@keyframes showContent {
  to {
    transform: translateY(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.slider .list .item.active .content .title {
  animation-delay: 0.4s;
}

.slider .list .item.active .content .type {
  animation-delay: 0.6s;
}

.slider .list .item.active .content .description {
  animation-delay: 0.8s;
  font-size: 22px;
}

.slider .list .item.active .content .buttons {
  animation-delay: 1s;
}

/* Animation for next button click */
.slider.next .list .item.active img {
  width: 150px;
  height: 220px;
  position: absolute;
  bottom: 50px;
  left: 70%;
  border-radius: 30px;
  animation: showImage 0.5s linear 1 forwards;
}

@keyframes showImage {
  to {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.slider.next .thumbnail .item:nth-last-child(1) {
  overflow: hidden;
  animation: showThumbnail 0.5s linear 1 forwards;
}
.slider.prev .list .item img {
  z-index: 100;
}

@keyframes showThumbnail {
  from {
    width: 0;
    opacity: 0;
  }
}

.slider.next .thumbnail {
  animation: effectNext 0.5s linear 1 forwards;
}

@keyframes effectNext {
  from {
    transform: translateX(150px);
  }
}

/* Animation for prev button click */
.slider.prev .list .item.prv {
  z-index: 2;
}

.slider.prev .list .item.prv img {
  animation: outFrame 0.5s linear 1 forwards;
  position: absolute;
  bottom: 0;
  left: 0;
}
@keyframes outFrame {
  to {
    width: 150px;
    height: 220px;
    bottom: 50px;
    left: 70%;
    border-radius: 20px;
  }
}

.slider.prev .thumbnail .item:nth-child(1) {
  overflow: hidden;
  opacity: 0;
  animation: showThumbnail 0.5s linear 1 forwards;
}
.slider.next .nextPrevArrows button,
.slider.prev .nextPrevArrows button {
  pointer-events: none;
}

.slider.prev .list .item.prv .content .title,
.slider.prev .list .item.prv .content .type,
.slider.prev .list .item.prv .content .description,
.slider.prev .list .item.prv .content .buttons {
  animation: contentOut 1.5s linear 1 forwards !important;
}

@keyframes contentOut {
  to {
    transform: translateY(-150px);
    filter: blur(20px);
    opacity: 0;
  }
}
@media screen and (max-width: 678px) {
  .slider .list .item .content {
    padding-right: 0;
  }
  .slider .list .item .content .title {
    font-size: 50px;
  }

  .slider .list .item .content .title,
  .slider .list .item .content .type {
    font-size: 35px;
    font-weight: bold;
    line-height: 1.3em;
    padding-right: 20px;
  }

  .thumbnail {
    bottom: 60px;
    left: 50%;
  }

  .slider .list .item.active .content .description {
    display: none !important;
  }

  .slider {
    height: 50vh;
  }
}

