.fslider-wrapper .carousel-item-container {
  position: relative;
  overflow: hidden;
}

.fslider-wrapper .scaling-image {
  width: 100%;
  height: auto;
  animation: scaleUp 20s ease-in-out infinite;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.fslider-wrapper  .carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay with transparency */
  z-index: 5; /* Positioned below the content */
}

.fslider-wrapper .carousel-content {
  position: absolute;
  bottom: 40%; /* Initially positioned below the view */
  left: 10%;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.fslider-wrapper .carousel-content .heading {
  position: relative; /* Ensure proper positioning for animation */
  font-size: 2rem;
  font-weight: bold;
  height: 60px;
  overflow: hidden;
}

.fslider-wrapper .carousel-content .heading h1 {
  font-size: 50px;
  animation: slideUp 2s ease-out;
}

.fslider-wrapper .carousel-content p {
  margin-top: 10px;
  width: 725px;
  font-size: 18px;
  z-index: 11;
  line-height: 1.5;
  animation: opacity 5s ease-out;
  font-family: var(--header-font) !important;
}

@keyframes opacity {
  0% {
    opacity: 0; /* Start with transparency */
  }
  50% {
    opacity: 0; /* Start with transparency */
  }
  100% {
    opacity: 1; /* Fully opaque */
  }
}

.fslider-wrapper .type {
  color: #ffc400;
  font-size: 3rem;
  font-weight: 600;
  font-family: var(--header-font) !important;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid transparent; /* Keep it hidden */
  animation: type 5s steps(90) 1.5s infinite normal both;
  position: relative;
  line-height: 60px;
}

@keyframes type {
  0% {
    width: 0;
  }
  50%,
  100% {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .fslider-wrapper .type {
    font-size: 1.3rem;
  }


  .fslider-wrapper .carousel-content p {
    display: none;
  }
}
