.slider-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 100px);
    overflow: hidden;
}

.slideshow {
    width: 100vw;
    height: calc(100vh - 100px);
    overflow: hidden;
    background: #110101;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

.slideshow .slide {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    transition: transform 1s ease-in-out;
}

.slideshow .slide.left {
    left: 0;
}

.slideshow .slide.right {
    right: 0;
}

.slideshow .slide.active {
    top: 50%;
    transform: translateY(-50%);
    display: flex;
}

.slideshow .slide.right.previous {
    transform: translateY(-150%);
    display: flex;
}

.slideshow .slide.right.next {
    transform: translateY(50%);
    display: flex;
}

.slideshow .slide.left.previous {
    transform: translateY(50%);
    display: flex;
}

.slideshow .slide.left.next {
    transform: translateY(-150%);
    display: flex;
}

.slideshow .item {
    height: calc(100vh - 100px);
    width: 50vw;
    position: relative;
    overflow: hidden;
    border: none;
}

.slideshow .item .shadow {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 11;
    height: 100%;
    width: 100%;
}
.slideshow .item img {
    width: 200% !important;
    max-width: 200%;
    /* min-height: 100%; */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.slideshow .left img {
    left: 0;
}

.slideshow .right img {
    right: 0;
}

.slideshow-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 200;
    pointer-events: none;
    /* background: #00000010; */
    padding: 16px;
}

.slideshow-text .item {
    /* height: 45px; */
    overflow: hidden;
}

.slideshow-text .item .heading {
    /* letter-spacing: 20px; */
    line-height: 1.3;
    font-size: 48px !important;
    text-transform: uppercase;
    width: 100%;
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.8s ease-in-out;

}

.slideshow-text .item .heading.active {
    transform: translateY(0%);
    opacity: 1;
}

.slideshow-text .item .heading.previous {
    transform: translateY(-100%);
    opacity: 0;
}

.slideshow-text .item .heading.next {
    transform: translateY(100%);
    opacity: 0;
}

.slideshow-text .description {
    display: block;
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 1s ease-in-out;
}

.slideshow-text .description.active {
    opacity: 1;
}

.slideshow-text .description p {
    max-width: 900px !important;
    font-size: 26px !important;
    margin: auto;
    padding: 16px;
    line-height: 1.2;
}

.dots {
    position: absolute;
    top: 50%;
    right: 32px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    z-index: 100;
    width: 50px;
    height: 50px;
}

.dot-line {
    border-top: solid 2px #fff;
    width: 60%;
    cursor: pointer;
    transition: width 0.8s ease;
}

.dot-line.active {
    width: 100%;
    transition: width 1s ease;
}

.up-btn, .down-btn {
    position: absolute;
    right: 16px;
    width: 50px;
    height: 50px;
    font-size: 32px;
    color: #fff;
    opacity: 0.2;
    z-index: 100;
    cursor: pointer;
}

.up-btn:hover, .down-btn:hover {
    opacity: 1;
}

.up-btn {
    top: 16px;
}

.down-btn {
    bottom: 16px;
}

@media (max-width: 767px) {
    .slideshow .item, .slideshow, .slider-container{
        height: 50vh;
    }
    .slideshow-text .item .heading {
        font-size: 30px;
        /* line-height: 1; */
    }

    .slideshow-text .description {
        display: none;
    }

    .dots {
        height: 28px;
        width: 28px;
    }

    .dot-line {
        border-top: solid 1px #fff;
    }

    .up-btn {
        display: none !important;
    }
    
    .down-btn {
       display: none !important;
    }
}

