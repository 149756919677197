.slider-four {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 100px) !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden; /* Add this to prevent overflow */
}

.img-slide {
  position: absolute;
  width: 100%;
  height:  100%;
  clip-path: circle(0% at 0 50%);
}

.img-slide.active {
  clip-path: circle(150% at 0 50%);
  transition: clip-path 10s ease;
  /* transition-property: clip-path; */
}

.content-text {
  z-index: 2 !important;
  color: #fff !important;
  width: 70%;
  margin-top: 50px;
  display: none;
  padding-left: 50px !important;
  font-family: var(--header-font) !important;
}

@keyframes slideInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.content-text h1 {
  color: #fff !important;
  animation: slideInFromTop 2s ease-in-out forwards;
}

.content-text p {
  color: #fff !important;
  animation: slideInFromBottom 2s ease-in-out forwards;
}

.content-text.active h1,
.content-text.active p {
  animation-delay: 0.5s;
}

.content-text.active {
  display: block;
}

.four-slider-navigation {
  z-index: 9;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(80px);
  margin-bottom: 12px;
  width: 100% !important;
  bottom: 25%;
}
/* 
.nav-btn {
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
  transition: 0.3s ease;
}

.nav-btn.active {
  background: #09a6d4;
}

.nav-btn:not(:last-child) {
  margin-right: 20px;
}

.nav-btn:hover {
  transform: scale(1.2);
} */

.four-shadow {
  background-color: #00000080;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}

/* New styles for next and previous buttons */
.four-prev-btn,
.four-next-btn {
  position: absolute;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 999;
}

.four-prev-btn {
  left: 40px;
  border-radius: 50px;
  padding: 8px 16px;
}

.four-next-btn {
  right: 40px;
  border-radius: 50px;
  padding: 8px 16px;
}

.four-prev-btn:hover,
.four-next-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 678px) {
  .content-text h1 {
    font-size: 23px !important;
  }
  .content-text p {
    display: none;
  }

  .slider-four {
    min-height: 30vh !important;
  }

  .four-prev-btn,
  .four-next-btn {
    bottom: 40%;
  }

  .four-slider-navigation {
    bottom: 28%;
  }
  .four-prev-btn,
.four-next-btn {
  display: none !important;
}
}
